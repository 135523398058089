import React from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { createGlobalStyle } from 'styled-components'

export const SentryStyles: React.FC = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus])
  const isSignedIn = authStatus === 'authenticated'

  const Style = createGlobalStyle`
  #sentry-feedback {
    display: none;
  }
`

  if (isSignedIn) {
    return null
  }

  return <Style />
}
