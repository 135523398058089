import React from 'react'
import {
  signIn,
  signUp,
  confirmSignUp,
  SignUpInput,
  SignUpOutput,
  ConfirmSignUpInput,
  ConfirmSignUpOutput,
} from 'aws-amplify/auth'
import { Authenticator, CheckboxField, useAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import styled from 'styled-components'
import { Link, LinkButton, Stack } from '@tumelo/designsystem'
import { supportEmail } from '@/config/contactSupport'
import { AuthHeader } from './AuthHeader'

interface Props {
  showConfirmMfaCode: () => void
}

export const AmplifyAuthenticator: React.FC<Props> = ({ showConfirmMfaCode }) => {
  return (
    <Authenticator
      loginMechanisms={['email']}
      signUpAttributes={['email', 'family_name', 'given_name', 'phone_number']}
      formFields={{
        signIn: {
          username: {
            isRequired: true,
            order: 1,
            placeholder: 'Enter your email',
          },
          password: {
            isRequired: true,
            order: 2,
            placeholder: 'Enter your password',
          },
        },
        signUp: {
          given_name: {
            label: 'First name*',
            placeholder: 'Enter your first name',
            isRequired: true,
            order: 1,
          },
          family_name: {
            label: 'Last name*',
            placeholder: 'Enter your last name',
            isRequired: true,
            order: 2,
          },
          email: {
            isRequired: true,
            order: 4,
            placeholder: 'Enter your email',
            label: 'Email*',
          },
          phone_number: {
            label: 'Phone number*',
            placeholder: '7700900123',
            dialCode: '+44',
            isRequired: true,
            order: 5,
          },
          password: {
            isRequired: true,
            order: 6,
            placeholder: 'Enter your password',
            label: 'Password*',
            minLength: 10,
          },
          confirm_password: {
            isRequired: true,
            order: 7,
            label: 'Confirm password*',
            placeholder: 'Please confirm your password',
            minLength: 10,
          },
        },
      }}
      components={{
        Header() {
          return <AuthHeader />
        },
        SignIn: {
          Footer() {
            const { toForgotPassword } = useAuthenticator()

            return (
              <Stack alignItems="center">
                <LinkButton size="small" onClick={toForgotPassword}>
                  Forgot your password?
                </LinkButton>
                <Link href={`mailto:${supportEmail}`} size="small">
                  Having trouble signing in?
                </Link>
              </Stack>
            )
          },
        },
        SignUp: {
          FormFields() {
            const { validationErrors } = useAuthenticator((context) => [context.validationErrors])
            return (
              <>
                <Authenticator.SignUp.FormFields />
                <CheckboxField
                  errorMessage={validationErrors.termsAndConditionsMessage as string}
                  hasError={!validationErrors.termsAndConditionsValid}
                  name="terms_and_conditions"
                  value="yes"
                  label={
                    <>
                      I agree with the{' '}
                      <StyledAnchor href="https://www.tumelo.com/terms-and-conditions" target="_blank">
                        terms &#38; conditions
                      </StyledAnchor>{' '}
                      and{' '}
                      <StyledAnchor href="https://www.tumelo.com/privacy-policy" target="_blank">
                        privacy policy
                      </StyledAnchor>
                      *{' '}
                    </>
                  }
                />
                <CheckboxField
                  errorMessage={validationErrors.individualInvestorMessage as string}
                  hasError={!validationErrors.individualInvestorValid}
                  name="individual_investor"
                  value="yes"
                  label="I confirm that I am a retail investor, investing my own money for personal purposes and not on behalf of any business or institution*"
                />
              </>
            )
          },
        },
      }}
      services={{
        handleSignIn: async (formData) => {
          const s = await signIn({
            username: formData.username,
            password: formData.password,
            options: {
              authFlowType: 'CUSTOM_WITH_SRP',
            },
          })
          if (s.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
            // this conditional allows us to use TOTP in our tests because the
            // authenticator component will display the correct form for the canaries to fill
            showConfirmMfaCode()
          }
          return s
        },
        async handleSignUp(input: SignUpInput): Promise<SignUpOutput> {
          const { username, password, options } = input
          const s = await signUp({
            username,
            password,
            options: {
              ...options,
              userAttributes: {
                ...input.options?.userAttributes,
              },
              autoSignIn: {
                authFlowType: 'CUSTOM_WITH_SRP',
              },
            },
          })
          if (s.nextStep.signUpStep === 'COMPLETE_AUTO_SIGN_IN') {
            // this conditional allows us to use TOTP in our tests because the
            // authenticator component will display the correct form for the canaries to fill
            showConfirmMfaCode()
          }

          return s
        },
        async handleConfirmSignUp(input: ConfirmSignUpInput): Promise<ConfirmSignUpOutput> {
          const s = await confirmSignUp(input)

          if (s.nextStep.signUpStep === 'COMPLETE_AUTO_SIGN_IN') {
            // this conditional allows us to use TOTP in our tests because the
            // authenticator component will display the correct form for the canaries to fill
            showConfirmMfaCode()
          }

          return s
        },
        async validateCustomSignUp(formData, touchData): Promise<ValidatorResult> {
          const errors: ValidationError = {}

          // Password validation
          const { password } = formData
          if (touchData.password) {
            const error = validatePassword(
              password,
              formData.email,
              formData.phone_number,
              formData.given_name,
              formData.family_name
            )

            if (error) {
              errors.password = error
            }
          }

          // Validation for custom checkboxes
          if (!formData.terms_and_conditions) {
            errors.termsAndConditionsMessage = touchData.terms_and_conditions
              ? 'You must agree to the terms & conditions and privacy policy'
              : ''
          }
          if (!formData.individual_investor) {
            errors.individualInvestorMessage = touchData.individual_investor
              ? 'You must confirm you are a retail investor'
              : ''
          }

          return Object.keys(errors).length ? errors : null
        },
      }}
    />
  )
}

const StyledAnchor = styled.a`
  text-decoration: underline;
`

// types not exported from amplify
type ValidationError = Record<string, string | string[]>
type ValidatorResult = void | null | ValidationError

export const validatePassword = (
  password: string,
  email: string,
  phoneNumber: string,
  givenName: string,
  familyName: string
): string | null => {
  if (!password) {
    return 'Password is required'
  }

  if (password.length < 10) {
    return 'Password must be at least 10 characters long'
  }

  const lowerPassword = password.toLowerCase()

  if (lowerPassword.includes(givenName.toLowerCase()) || lowerPassword.includes(familyName.toLowerCase())) {
    return 'Password cannot contain your first or last name'
  }

  if (lowerPassword.includes(email.toLowerCase())) {
    return 'Password cannot contain your email address'
  }

  if (lowerPassword.includes(phoneNumber.toLowerCase())) {
    return 'Password cannot contain your phone number'
  }

  if (lowerPassword.includes('tumelo') || lowerPassword.includes('proxysphere')) {
    return 'Password cannot reference the platform name'
  }

  return null // No errors
}
