import React from 'react'
import { GlobalStyle } from '@tumelo/designsystem'
import {
  LoggerService,
  LoggerServiceConsole,
  LoggerServiceSentry,
  LoggerServiceCompound,
  LoggerProvider,
} from '@tumelo/logging'
import { Auth } from '@/application/Auth'
import { getEnvironmentConfig } from '@/application/Environment/getEnvironmentConfig'
import { CookieBanner } from '@/components/CookieBanner'
import * as Analytics from '@/application/Providers/AnalyticsProvider'
import { AuthProvider } from './AuthProvider'
import { StyledComponentsThemeProvider } from './ThemeProvider'

export const Providers: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <LoggerProvider logger={setupLoggingService()}>
      <StyledComponentsThemeProvider>
        <GlobalStyle />
        <CookieBanner>
          <Auth>
            <AuthProvider>
              <Analytics.Provider>
                <Analytics.SetUserProperties>
                  <Analytics.PageViewFromLocationPathname>{children}</Analytics.PageViewFromLocationPathname>
                </Analytics.SetUserProperties>
              </Analytics.Provider>
            </AuthProvider>
          </Auth>
        </CookieBanner>
      </StyledComponentsThemeProvider>
    </LoggerProvider>
  )
}

const setupLoggingService = (): LoggerService => {
  const { environment } = getEnvironmentConfig()

  const dsn = 'https://7d5ae0c85c7bc1e7aa2e2f6afba1df2b@o365838.ingest.us.sentry.io/4507095225466880'
  // feedback widget disabled in prod before getting some product feedback
  const sentryLogger = new LoggerServiceSentry({ dsn, environment, enableFeedbackWidget: true })
  return new LoggerServiceCompound(LoggerServiceConsole, sentryLogger)
}
