import * as Sentry from '@sentry/browser'
import { Integration } from '@sentry/core'
import { UserData, LoggerService, Context } from './Logger'

export class LoggerServiceSentry implements LoggerService {
  constructor(init: { dsn: string; environment?: string; tracesSampleRate?: number; enableFeedbackWidget?: boolean }) {
    if (!Sentry.getClient()) {
      const integrations: Integration[] = [
        Sentry.browserTracingIntegration() as Integration,
        Sentry.replayIntegration({
          // stop PII going into replays
          maskAllText: true,
          blockAllMedia: true,
        }) as Integration,
      ]

      if (init.enableFeedbackWidget) {
        integrations.push(
          Sentry.feedbackIntegration({
            triggerLabel: 'Got feedback?',
            formTitle: 'Got feedback?',
            messageLabel: 'Feedback',
            messagePlaceholder: 'Share your feedback or describe an issue',
            submitButtonLabel: 'Submit',
            showBranding: false,

            themeLight: {
              // easier to redefine colours than import them from the design system
              foreground: '#3a3c62',
              accentForeground: '#FFFFFF',
              accentBackground: '#207F82',
              outline: '#8F91A3',
              errorColor: '#aa0e43',
              successColor: '#2eb6b9',
              boxShadow: 'rgba(0, 0, 0, 0.16)',
            },
          })
        )
      }

      Sentry.init({
        environment: 'development',
        integrations,
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        ...init,
      })
    }
  }

  setUserData(u: UserData): void {
    Sentry.setUser({
      id: u.id,
    })
  }

  logError(e: Error | undefined, context?: Context): void {
    Sentry.captureException(e, context)
  }
}
