import { createGlobalStyle } from 'styled-components'
import { SoftConfigThemeSchema, screen } from '@tumelo/designsystem'

const defaultTheme = SoftConfigThemeSchema.parse()

export const AmplifyStyles = createGlobalStyle`
  [data-amplify-authenticator] {
    background-color: ${defaultTheme.colors.primary.dark};
    min-height: 100vh;
  }

  body {
    margin: 0;
  }

  /* Apply button style to create account button */
  .amplify-tabs .tab:last-of-type .amplify-button--fullwidth.amplify-field-group__control {
    text-transform: lowercase;
    display: block;
    text-align: center;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .amplify-button {
    font-size: 16px;
    padding: 8px;

    &--small {
      font-size: 1.4rem;
      line-height: 2rem;
      @media ${screen.smallUp} {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
      font-weight: 400;
      background: none;
      text-decoration: underline;
    }
  }

  .amplify-label {
    font-size: 16px;
  }

  .amplify-input {
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 4px;
  }

  .amplify-flex {
    gap: 16px;
  }

  .amplify-field {
    gap: 8px;
  }

  .amplify-field-group {
    gap: 0;

    .amplify-field-group__control {
      padding: 8px 16px;
    }
  }

  .amplify-tabs__list button {
    padding: 12px;
  }

  /* Apply LayoutWrapper styles */
  body > div#__next {
    display: flex;
    flex-direction: column;
    overflow: inherit;
    width: auto;
    height: 100vh;
  }

  [data-amplify-authenticator] [data-amplify-container] {
    place-self: start center;
    margin-top: 10%;
    margin-bottom: 100px;
    width: 480px;
    max-width: 90vw;

    form {
      padding: 32px;
    }
  }

  [data-amplify-authenticator] [data-amplify-router] {
    border-radius: 8px;
    border-width: 0;
  }

  .amplify-tabs__list {
    border-radius: 8px;
    border-width: 1px 0 0 0;
  }

  .amplify-tabs__item {
    border-width: 4px 0 0 0;
    border-radius: 0 8px 0 0;
    font-size: 16px;
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .amplify-tabs__item:first-child {
    border-radius: 8px 0 0 0;
  }

  .amplify-text {
    font-size: 16px;
  }

  .amplify-field__error-message {
    font-size: 14px;
  }

  .amplify-select {
    font-size: 16px;
  }

  .amplify-checkbox {
    gap: 8px;
  }

  .amplify-checkbox__button {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    font-size: 16px;
  }

  .amplify-alert {
    padding: 12px 16px;
    font-size: 16px;

    .amplify-alert__icon {
      font-size: 24px;
    }
  }

  .amplify-fieldset {
    gap: 5px;
  }
`
