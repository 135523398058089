import { Amplify, ResourcesConfig } from 'aws-amplify'
import { I18n } from 'aws-amplify/utils'
import { Authenticator } from '@aws-amplify/ui-react'
import React, { useMemo } from 'react'
import '@aws-amplify/ui-react/styles.css'
import { getEnvironmentConfig } from '@/application/Environment/getEnvironmentConfig'
import { AmplifyStyles } from './AmplifyStyles'
import { AuthContainer } from './AuthContainer'
import { SentryStyles } from './SentryStyles'

export const Auth: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { userpoolId, appClientId } = getEnvironmentConfig()

  useMemo(() => {
    const authConfig: ResourcesConfig = {
      Auth: {
        Cognito: {
          userPoolId: userpoolId,
          userPoolClientId: appClientId,
        },
      },
    }

    Amplify.configure(authConfig)
    return Auth
  }, [userpoolId, appClientId])

  return (
    <Authenticator.Provider>
      <SentryStyles />
      <AmplifyStyles />
      <AuthContainer>{children}</AuthContainer>
    </Authenticator.Provider>
  )
}

const authLabels = {
  en: {
    'Provided password cannot be used for security reasons.':
      "We can't use your password, please use a password you've not used before. For strengthened security, try using three random words to make a strong passphrase, include a mix of letters, numbers and symbols or use a password manager to create one.",
  },
}
I18n.setLanguage('en')
I18n.putVocabularies(authLabels)
